<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>Particulars</th>
                    <th class="text-right">Amount</th>
                    <th class="text-right">Total</th>
                </tr>
                </thead>

                <tbody v-for="(item, i) in service" :key="i">
                <tr class="bg-blue-light cursor-pointer" @click="goToPrint(item.id, item.name)">
                    <td colspan="3">{{ item.name }}</td>
                </tr>

                <tr v-for="(el, index) in item.child" :key="index">
                    <td>{{ el.name }}</td>
                    <td class="text-right">{{ commaFormat(el.total_amount) }}</td>
                    <td v-if="index == 0" :rowspan="item.child.length + 1" class="text-right text-bold">
                        {{ commaFormat(calculateTotal(item.child)) }}
                    </td>
                </tr>
                </tbody>
            </table>

            <div class="row py-2">
                <div class="col-md-6 d-flex align-items-center">
                    <span v-if="service.length && netAmount > totalPaidAmount" class="py-1 px-2 due">Due</span>
                    <span v-if="service.length && netAmount <= totalPaidAmount" class="py-1 px-2 paid">Paid</span>
                </div>

                <div v-if="service.length > 0" class="col-md-6 table-responsive">
                    <table class="table">
                        <tbody>
                        <tr>
                            <td class="text-right text-bold">Total Amount</td>
                            <td class="text-right">{{ commaFormat(total.amount) }}</td>
                        </tr>
                        <tr>
                            <td class="text-right text-bold">Bill Discount</td>
                            <td class="text-right">{{ commaFormat(total.discount) }}</td>
                        </tr>
                        <tr>
                            <td class="text-right text-bold">Settlement Discount</td>
                            <td class="text-right">{{ commaFormat(total.settlement_discount) }}</td>
                        </tr>
                        <tr>
                            <td class="text-right text-bold">Net Bill Amount</td>
                            <td class="text-right">{{ commaFormat(netAmount) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div v-if="receiptGenerals.length > 0" class="table-responsive">
                <table class="table">
                    <thead>
                    <tr>
                        <th>MR Date & Time</th>
                        <th>MR No</th>
                        <th>Mode of payment</th>
                        <th>MoP Ref.</th>
                        <th>Receipt by</th>
                        <th class="text-right">Receipt Amount</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="(item, index) in receiptGenerals" :key="index">
                        <td>{{ formatDateWithTime(item.created_at) }}</td>
                        <td>{{ item.voucher_no }}</td>
                        <td>{{ item.account_head.name }}</td>
                        <td>{{ item.receipt_reference }}</td>
                        <td>{{ item.created_user.name }}</td>
                        <td class="text-right">{{ commaFormat(item.total_paid_amount) }}</td>
                    </tr>

                    <tr>
                        <td colspan="5" class="text-right">Total</td>
                        <td class="text-right">{{ commaFormat(totalPaidAmount) }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <p v-if="!receiptGenerals.length" class="text-center mt-5">No data</p>
        </div>

        <div class="row py-2">
            <div class="col-md-5">
                <MoneyReceiptForm :formData="formData" :grand-total="netPayable"/>
            </div>
            <div v-if="service.length > 0" class="offset-md-2 col-md-5">
                <table class="table">
                    <tbody>
                    <tr>
                        <td class="text-right text-bold">Net Bill Amount</td>
                        <td class="text-right">{{ commaFormat(netAmount) }}</td>
                    </tr>
                    <tr>
                        <td class="text-right text-bold">Total Paid</td>
                        <td class="text-right">{{ commaFormat(totalPaidAmount) }}</td>
                    </tr>
                    <tr>
                        <td class="text-right text-bold">Due / Return Amount</td>
                        <td class="text-right">{{ commaFormat(formData.bill_amount) }}</td>
                    </tr>
                    <tr>
                        <td class="text-right text-bold">
                            Discount / Round up Adj
                            <div class="text-right">
                                <span class="mr-5"></span>
                                <input
                                    v-model.trim="discountPercent"
                                    type="number"
                                    class="form-control form-control-sm discount-adj ml-auto text-right"
                                    placeholder="%"
                                    :min="0"
                                >
                            </div>
                        </td>
                        <td class="text-right">
                            <div class="text-right">
                                <span class="mr-5"></span>
                                <input
                                    v-model.trim="discountAmount"
                                    type="number"
                                    class="form-control form-control-sm discount-adj ml-auto text-right mt-1"
                                    placeholder="amount" :min="0"
                                >
                            </div>
                        </td>
                    </tr>
                    <tr v-if="discountAmount > 0" class="text-right text-bold">
                        <td class="product px-2">Select discount head</td>
                        <td style="font-weight: normal">
                            <v-select
                                placeholder="Select Discount Head"
                                label="name"
                                v-model="formData.discount_head_id"
                                :options="salesAccountHeads"
                                :reduce="name => name.id"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="text-right text-bold">Net Payable</td>
                        <td class="text-right">{{ commaFormat(netPayable) }}</td>
                    </tr>
                    <tr>
                        <td class="text-right text-bold">Last Settlement Amount</td>
                        <td class="text-right">{{ commaFormat(settlementReceipt.total_paid_amount) }}</td>
                    </tr>
                    <tr>
                        <td class="text-right text-bold">Revised Paid Amount</td>
                        <td class="text-right">{{ commaFormat(formData.payment) }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-12 d-flex justify-content-center mt-2">
            <button
              class="btn btn-success"
              @click="handleUpdateSettlement"
            >
              Update Settlement
            </button>
        </div>
    </div>
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";
import handleHospital from '@/services/modules/hospital';
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref, watch} from "vue";
import ipdStatementDetailsHelper from '@/services/utils/pdf/ipdStatementDetailsHelper';
import useDate from '@/services/utils/day';
import handlePurchase from "@/services/modules/purchase";
import MoneyReceiptForm from "@/components/molecule/company/hospital/ipd-station/MoneyReceiptIpdUpdateForm.vue";
import { inject } from 'vue'
import ipdStatementHelper from "@/services/utils/pdf/ipdStatementHelper";

const $route = useRoute();
const $router = useRouter();

const {commaFormat} = figureFormatter();
const {fetchIpdStatementDetails, updateSettlement, fetchIpdStatement} = handleHospital();
const {generatePdf} = ipdStatementDetailsHelper();
const {formatDateWithTime} = useDate();
const {getAccountHeadBySlag} = handlePurchase()
const { generatePdf: genIpdPDF } = ipdStatementHelper();
const showError = inject('showError')
const showSuccess = inject('showSuccess')

const props = defineProps({
    service: {
        type: Array,
    },
    total: {
        type: Object
    },
    receiptGenerals: {
        type: Array
    },
    settlementLedgerGenerals: {
        type: Array
    },
    patientDetails: {
        type: Object
    },
    companyInfo: {
        type: Object
    },
    settlementReceipt: {
        type: Object
    }
})

const data = ref({});
const saleMaster = ref([]);
const discountPercent = ref(null);
const discountAmount = ref(null);
const salesAccountHeads = ref([]);
const formData = ref({
    money_receipt_id: null,
    date: '',
    company_id: $route.params.companyId,
    contact_profile_id: null,
    ipd_register_id: null,
    receivable_account_head_id: null,
    mop_account_head_id: null,
    discount_amount: 0,
    discount_head_id: null,
    reference: '',
    payment: null,
})

const goToPrint = (id, name) => {
    const query = `?company_id=${$route.params.companyId}`
    fetchIpdStatementDetails(props.patientDetails.id, id, query).then((res) => {
        data.value = res.data;
        data.value.name = name;
        saleMaster.value = res.sale_masters
    }).then(() => {
        generatePdf(props.companyInfo, data.value, saleMaster.value)
    }).catch(e => console.log(e))
}

const calculateTotal = (child) => child.reduce((total, item) => total + item.total_amount, 0);

const totalPaidAmount = computed(() => props.receiptGenerals.reduce((total, item) => total + item.total_paid_amount, 0));
const netAmount = computed(() => (props.total.amount - props.total.discount - props.total.settlement_discount))
const netPayable = computed(() => (netAmount.value - totalPaidAmount.value) - discountAmount.value)
const patient = computed(() => props.patientDetails);
const settlementDetails = computed(() => props.settlementReceipt);
const settlementGenerals = computed(() => props.settlementLedgerGenerals);

watch(discountPercent, () => {
    if (discountPercent.value === 0) {
        return discountAmount.value = 0;
    }
    return discountAmount.value = ((netAmount.value - totalPaidAmount.value) * discountPercent.value) / 100;
})

watch(netAmount, () => {
    formData.value.bill_amount = (netAmount.value - totalPaidAmount.value);
})

watch(discountAmount, (newValue) => {
    if(newValue && newValue < 0) {
      showError("Discount can not be less than 0");
      discountAmount.value = 0;
    }
    formData.value.discount_amount = parseFloat(discountAmount.value);
})


watch(patient, (newValue) => {
    if (newValue) {
        formData.value.contact_profile_id = newValue.contact_profile_id;
        formData.value.ipd_register_id = newValue.id;
    }
})


watch(settlementDetails, (newValue) => {
    if (newValue) {
        formData.value.receipt_master_id = newValue.id
        formData.value.payment = newValue.total_paid_amount;
        formData.value.date = newValue.receipt_date;
        discountAmount.value = newValue.discount_amount;
        formData.value.mop_account_head_id = newValue.account_head_id;
        formData.value.discount_head_id = newValue.discount_account_head_id;
        formData.value.reference = newValue.receipt_reference
    }
})


watch(settlementGenerals, (newValue) => {
    if (newValue && Array.isArray(newValue)) {
        formData.value.ledger_details = {
            discount_ledger: newValue.find(led => {
                return led.account_head_id === settlementDetails.value.discount_account_head_id
            }),
            payment_ledger: newValue.find(led => {
                return led.account_head_id === settlementDetails.value.account_head_id
            }),
            bill_ledger: newValue.find(led => {
                return led.account_head_id === formData.value.receivable_account_head_id
            })
        }
    }
})



const handleUpdateSettlement = () => {
    updateSettlement(formData.value.ipd_register_id, formData.value)
        .then(res => {
            if (res.status) {
                showSuccess(res.message)
                const query = `/${$route.query.id}?company_id=${$route.params.companyId}`;
                let total = {};
                fetchIpdStatement(query).then((res) => {
                    total = res.total;
                    total.settlement_discount = res.data.settlement_discount;
                    genIpdPDF(props.companyInfo, res.data, res.service_data, res.receipt_generals, total);
                    delete $route.query.id;
                    $router.push({name: 'patient-register',
                      params: $route.params,
                      query: $route.query
                    })
                })
                return;
            }

            showError(res.message)
        })
        .catch(err => {
            showError(err.message)
        })
}

onMounted(() => {
    const companyQuery = `?company_id=${$route.params.companyId}`;
    getAccountHeadBySlag("sales_accounts", companyQuery).then(res => {
        if(res.data) salesAccountHeads.value = res.data
    })
})

</script>

<style scoped>
.paid {
    background-color: #27b032;
    color: #fff;
    font-size: 24px;
    border-radius: 15px;
}

.due {
    background-color: #000;
    color: #fff;
    font-size: 24px;
    border-radius: 15px;
}
.discount-adj {
    width: 60%;
}
</style>
